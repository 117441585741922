import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from "@/store";
import {Tool} from "@/util/tool";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home/Home.vue')
  },
  {
    path: '/query',
    name: 'Query',
    component: () => import('../views/Query/Query.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if ( to.matched.some(item => {
    return item.meta.isLogin
  }) ) {
    const loginUser = store.state.user
    if ( Tool.isEmpty(loginUser) ) {
      next('/login')
    } else {
      next()
    }
  } else {
    const loginUser = store.state.user
    if ( !Tool.isEmpty(loginUser) ) {
      next('/')
    } else {
      next()
    }
  }
})

export default router
